<template>
  <div v-if="invoiceDetail !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-between items-center">
        <h1 class="heading-2 text-center">Invoice Number:  #{{invoiceDetail.invoiceNumber}}</h1>
        <div class="flex items-center gap-3">
          <!-- <div class=" text-error heading-4 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
            <i class="fa-solid fa-trash"></i>
          </div> -->
           <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="isSendEmail()">
            <i class="fas fa-envelope"></i>
          </div>
          <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="updateInvoice(item)">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>
          <div class="relative">
                <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
                  <i class="fas fa-ellipsis-v"></i>
                </div>
                <div v-if="showMenuList">
                  <div v-click-outside="closeList">
                    <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-64">
                        <div class="p-2 text-text2 font-medium text-center">
                          <!-- <div @click.stop="isSendEmail()" class="p-2 cursor-pointer hover:bg-blue-50">
                            <span class="py-1">Delete invoice</span>
                          </div> -->
                          <div class="p-2 cursor-pointer hover:bg-red-50" @click="removeConfirm = true">
                            <span class="py-1 text-error">Delete Invoice</span>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            <!-- <span  @click="removeConfirm = true" class="text-error cursor-pointer heading-4">
                <i class="fa-solid fa-trash"></i>
                </span>
              <span @click="updateInvoice(item)" class="cursor-pointer text-white heading-4"><i class="fa-solid fa-pen-to-square"></i></span> -->
        </div>
      </div>
    </div>
    <div class="py-2">
      <div class="card bg-white rounded-xl p-4 my-2" v-if="invoiceDetail.notes !== ''">
        <div class="text-gray3 font-bold">Invoice Note </div>
        <div class="text-text1 heading-5">{{invoiceDetail.notes}}</div>
      </div>
    </div>
    <div>
         <div class="lg:grid grid-cols-12 gap-4">
            <div class="col-span-4 2xl:col-span-4">
              <div class="card bg-white rounded-xl p-4 mb-4">
                    <div class="mb-4">
                      <!-- <span v-if="isCustomerPermission" class="text-primary heading-2  font-bold cursor-pointer" @click="$router.push({name: 'customerDetails', params: {customerId: invoiceDetail.customerId}})">{{invoiceDetail.companyName}}</span>
                        <span v-else class="text-primary heading-2  font-bold" >{{invoiceDetail.companyName}}</span> -->
                        <div class="flex justify-between">
                <div class="">
                <span v-if="isCustomerPermission" class="cursor-pointer text-primary font-semibold heading-2" @click.stop="customerDetail()">{{invoiceDetail.companyName}} ({{ invoiceDetail.isLead ? 'Lead' : 'Customer' }})</span>
                <span v-else class="text-primary font-semibold heading-2">{{invoiceDetail.companyName}} ({{ invoiceDetail.isLead ? 'Lead' : 'Customer' }})</span>
                </div>
                <div v-if="invoiceDetail.customerHotNotes !== ''" class=" text-right">
                  <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes"> {{showHotNOtes ? 'Hide' : 'Show'}} hot notes</span>
                </div>
              </div>
              <div v-if="showHotNOtes" class="">
                  <span class="cursor-pointer text-secondary">
                    {{invoiceDetail.customerHotNotes}}
                  </span>
                </div>
                    </div>
                    <div class=" flex justify-between">
                      <div class=" mb-4">
                        <p class="text-gray4">Invoice Date</p>
                        <p class="font-bold text-text1">{{invoiceDetail.invoiceDate | formatForDatePicker}}</p>
                    </div>
                    <div class=" mb-4">
                        <p class="text-gray4 text-right">Due Date</p>
                        <p class="font-bold text-text1 text-right">{{invoiceDetail.dueDate | formatForDatePicker}}</p>
                    </div>
                    
                    </div>
                    <div class=" mb-4">
                        <p class="text-gray4">Status</p>
                        <p :style="`color: ${invoiceDetail.invoiceStatusColor}`" class="font-bold text-text2">{{invoiceDetail.invoiceStatusName}}</p>
                    </div>
                    <div class="mb-4">
                        <p class="text-gray4 ">Billing Address:</p>
                        <p class="text-text1 heading-7" v-if="invoiceDetail.billingLine1 !== '' ||  invoiceDetail.billingLine2 !== '' ||  invoiceDetail.billingCity !== '' || invoiceDetail.billingState !== '' || invoiceDetail.billingZip !== ''">
                            <span>{{invoiceDetail.billingLine1}}<span v-if="invoiceDetail.billingLine1 !== '' &&  invoiceDetail.billingLine2 !== ''">, </span></span>
                            <span>{{invoiceDetail.billingLine2}}<span v-if="invoiceDetail.billingLine2 !== '' &&  invoiceDetail.billingCity !== ''">, </span></span>
                            <span>{{invoiceDetail.billingCity}}<span v-if="invoiceDetail.billingCity !== '' &&  invoiceDetail.billingState !== ''">, </span></span>
                            <span>{{invoiceDetail.billingState}} <span v-if="invoiceDetail.billingState !== '' &&  invoiceDetail.billingZip !== ''"></span></span>
                            <span v-if="invoiceDetail.billingZip !== ''">-{{invoiceDetail.billingZip}} </span>
                         </p>
                         <p class="text-text1 heading-7" v-else>-</p>
                    </div>
                    <div>
                        <p class="text-gray4 ">Shipping Address:</p>
                        <p class="text-text1 heading-7" v-if="invoiceDetail.shippingLine1 !== '' ||  invoiceDetail.shippingLine2 !== '' || invoiceDetail.shippingCity !== '' || invoiceDetail.shippingState !== '' || invoiceDetail.shippingZip !== ''">
                            <span>{{invoiceDetail.shippingLine1}} <span v-if="invoiceDetail.shippingLine1 !== '' &&  invoiceDetail.shippingLine2 !== ''">, </span></span> 
                            <span>{{invoiceDetail.shippingLine2}}<span v-if="invoiceDetail.shippingLine2 !== '' &&  invoiceDetail.shippingCity !== ''">, </span></span>
                            <span>{{invoiceDetail.shippingCity}}<span v-if="invoiceDetail.shippingCity !== '' &&  invoiceDetail.shippingState !== ''">, </span></span>
                            <span>{{invoiceDetail.shippingState}} <span v-if="invoiceDetail.shippingState !== '' &&  invoiceDetail.shippingZip !== ''"></span></span>
                            <span v-if="invoiceDetail.shippingZip !== ''">-{{invoiceDetail.shippingZip}} </span>
                         </p>
                         <p class="text-text1 heading-7" v-else>-</p>
                    </div>
              </div>
              <div class="card bg-white rounded-xl p-4 mb-4">
                <div class="text-gray4 flex justify-between mb-4">
                  <div>
                    <p>Sales Person</p>
                    <p v-if="invoiceDetail.salesPersonName !== ''" class="text-text2">{{invoiceDetail.salesPersonName}}</p>
                    <p v-else class="text-text2">-</p>
                  </div>
                  <div>
                    <p>Created By</p>
                    <p class="text-text1 text-right">{{invoiceDetail.createdByUserFirstName}} {{invoiceDetail.createdByUserLastName}}</p>
                  </div>
                </div>
              </div>
              <div v-if="invoiceDetail.jobList.length > 0" class="card bg-white rounded-xl p-4 mb-4">
                <div class="text-gray4 flex justify-between">
                  <div>
                    <p>Job</p>
                    <p class="text-primary cursor-pointer" @click="$router.push({name: 'jobDetail', params: {jobId: invoiceDetail.jobList[0].visitId}})">{{invoiceDetail.jobList[0].jobTitle}}</p>
                  </div>
                </div>
              </div>
              <div v-if="invoiceDetail.visitList.length > 0" class="card bg-white rounded-xl p-4 mb-4">
                <div class="text-gray4 flex justify-between">
                  <div>
                    <p>Visit</p>
                    <p class="text-primary cursor-pointer" @click="$router.push({name: 'visitDetail', params: {visitId: invoiceDetail.visitList[0].visitId}})">{{invoiceDetail.visitList[0].jobTitle}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-span-8 2xl:col-span-6 mb-10">
              <div class="card bg-white rounded-xl p-4 mb-4">
                <div  class="" v-if="invoiceDetail.selectedItemList.length > 0">
                  <p class=" text-gray4 heading-4 font-bold mb-4">Items list:</p>
                  <div class="px-4 cursor-pointer hover:bg-gray1 border-b py-2" v-for="(data, index) in invoiceDetail.selectedItemList" :key="index" @click="showItemDetail(data)">
                    <div class="text-text2 text-sm flex mb-2 font-bold justify-between">
                      <p class="pr-4" >{{data.itemName}}</p>
                      <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
                    </div>
                    <div class="text-text2 flex">
                      <p class="w-32 sm:w-72 heading-6">{{data.itemPrice | amountOnlyNumber}}</p>
                      <span class="w-72 heading-6">Qty:{{data.itemQty}} <span class="pl-4" v-if="data.isTaxable">Taxed</span></span>
                    </div>
                    <div class="text-text2 flex">
                      <p class="heading-7 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
                    </div>
                  </div>
                </div>
                  <div class="flex justify-between py-1 px-4">
                    <p class="text-text2 font-bold w-full text-right">Items Total (A):</p>
                    <p class="text-text2 font-bold w-full text-right">{{invoiceDetail.subTotal.toFixed(2) | amountOnlyNumber}}</p>
                  </div>
                  <div class="divider mt-2"></div>
                 <div v-for="(taxes, index) in finalTaxList" :key="index" class="hover:bg-gray1 border-b py-2 heading-6">
                    <div @click="showBreakDownDetail(taxes.taxId)" class="px-4 cursor-pointer">
                      <div class="flex w-full justify-between ">
                        <div class="text-gray4 w-28 sm:w-32">{{taxes.taxName.substr(0, 20)}} <span v-if="taxes.taxName.length > 20">...</span>
                        <p class="text-gray4 flex sm:hidden sm:w-32">({{taxes.taxValue.toFixed(3)}} %)</p>
                        </div>
                        <p class="text-gray4 hidden sm:flex w-20 sm:w-32">({{taxes.taxValue.toFixed(3)}} %)</p>
                        <p class="text-gray4 sm:w-32 text-right">{{taxes.ammount.toFixed(2) | amountOnlyNumber}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between py-2 px-4">
                    <p class="text-text2 font-bold w-full text-right">Total Tax (B):</p>
                    <p class="text-text2 font-bold w-full text-right">{{taxesAmmount.toFixed(2) | amountOnlyNumber}}</p>
                  </div>
                  <div class="divider my-2"></div>
                  <div class="flex justify-between pt-2 px-4">
                    <p class="text-text2 font-bold w-full text-right">Total  (A + B):</p>
                    <p class="text-text2 font-bold w-full text-right">{{invoiceDetail.grandTotal.toFixed(2) | amountOnlyNumber}}</p>
                  </div>
                  <div v-if="invoiceDetail.totalPaymentRecord > 0" class=" px-4 flex justify-between pb-2 pt-1">
                    <p class="text-success w-full text-right">Payment Recorded:</p>
                    <p class="text-success font-bold w-full text-right">{{invoiceDetail.totalPaymentRecord.toFixed(2) | amountOnlyNumber}}</p>
                  </div>
                  
                  <div class="divider my-2"></div>
                  <div v-if="invoiceDetail.grandTotal - invoiceDetail.totalPaymentRecord > 0" class="flex justify-between pb-2 pt-1 px-4">
                    <p class="text-error w-full font-bold text-right">Due:</p>
                    <p class="text-error w-full font-bold text-right">{{(invoiceDetail.grandTotal - invoiceDetail.totalPaymentRecord).toFixed(2) | amountOnlyNumber}}</p>
                  </div>
                  <div v-if="invoiceDetail.invoiceStatusId !== 4" class="flex justify-end mt-2 px-4">
                    <div class="">
                      <Button @buttonAction="recordPayment()" :btnSize="'large'" :textColor="'white'" :btnColor="'success'" :btnText="'Record Payment'"/>
                    </div>
                  </div>
                </div>
                <div class="card bg-white rounded-xl p-4 mb-4" v-if="invoicePaymentRecords.length > 0">
                  <div class="flex items-center justify-between cursor-pointer" @click.stop="displayTagSelects = !displayTagSelects">
                    <p class=" text-success heading-4 font-bold">{{invoicePaymentRecords.length}} Payment Entry{{invoicePaymentRecords.length > 1 ?'s' : ''}}</p>
                    <span class="right-6 ">
                      <p v-if="displayTagSelects"><i class="fas fa-chevron-up text-primary heading-5"></i></p>
                      <span v-if="!displayTagSelects"><i class="fas fa-chevron-down text-primary heading-5"></i></span>
                    </span>
                  </div>
                  <div>
                    <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="displayTagSelects">
                      <div v-for="(data, index) in invoicePaymentRecords" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="paymentDetail(data.paymentId)">
                        <div class="flex items-center justify-between">
                          <p class="text-gray4 heading-5">{{data.addedDate | formatDateTime2}}</p>
                          <p class="text-gray4 heading-5 pr-2">{{(data.paymentAmount).toFixed(2) | amountOnlyNumber}}</p>
                        </div>
                        <div class="flex items-center justify-between">
                          <p class="text-text2 heading-6">{{data.paymentMethodName}}</p>
                          <p class="text-text2 heading-6 pr-2">Recorded By {{data.createdByUserFirstName}} {{data.createdByUserLastName}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div style="overflow-y: auto; white-space: nowrap;" class="">
                    <div v-if="invoicePaymentRecords.length > 0">
                        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                          <div class="table_col w-32  flex items-center">
                            <span class="cursor-pointer">{{titleObj[0].title}}</span>
                          </div>
                          <div class="table_col w-40  flex items-center">
                            <span class="cursor-pointer">{{titleObj[1].title}}</span>
                          </div>
                          <div class="table_col w-32  flex items-center">
                            <span class="cursor-pointer">{{titleObj[2].title}}</span>
                          </div>
                          <div class="table_col w-32  flex items-center">
                            <span class="cursor-pointer">{{titleObj[3].title}}</span>
                          </div>
                          <div class="table_col w-40  flex items-center">
                            <span class="cursor-pointer">{{titleObj[4].title}}</span>
                          </div>
                          <div class="table_col w-32  flex items-center">
                            <span class="cursor-pointer">{{titleObj[5].title}}</span>
                          </div>
                        </div>
                        <div v-for="(data, index) in invoicePaymentRecords" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="paymentDetail(data.paymentId)">
                          <div class="table_col w-32 ">
                            <div class="">
                              <p class="  text-text2 font-bold" v-if="data.companyName !== ''">{{data.companyName}}</p>
                              <p class="  text-text2 font-bold" v-else>-</p>
                            </div>
                          </div>
                          <div class="table_col w-40 ">
                            <div class="flex items-center ">
                              <span class="  text-gray4 heading-6" v-if="data.transactionId !== ''">{{data.transactionId}}</span>
                              <span class="  text-gray4 heading-6 ml-10" v-else>-</span>
                            </div>
                          </div>
                          <div class="table_col w-32">
                            <div class="flex items-center">
                              <span class="  text-gray4 heading-6" v-if="data.paymentMethodName !== ''">{{data.paymentMethodName}}</span>
                              <span class="  text-gray4 heading-6" v-else>-</span>
                            </div>
                          </div>
                          <div class="table_col w-32 ">
                            <div class="flex items-center">
                              <span class="  text-gray4 heading-6" v-if="data.paymentAmount !== ''">{{data.paymentAmount | amountOnlyNumber}}</span>
                              <span class="  text-gray4 heading-6" v-else>-</span>
                            </div>
                          </div>
                          <div class="table_col w-40 ">
                            <div class="flex items-center">
                              <span v-if="data.createdByUserFirstName !== ''"  class=" text-gray4 heading-6">{{data.createdByUserFirstName + ' ' + data.createdByUserLastName}}</span>
                              <span class="  text-gray4 heading-6" v-else>-</span>
                            </div>
                          </div>
                          <div class="table_col w-32 ">
                            <div class="flex items-center">
                              <span v-if="data.addedDate !== ''"  class=" text-gray4 heading-6">{{data.addedDate | formatForDatePicker}}</span>
                              <span class="  text-gray4 heading-6" v-else>-</span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div> -->
                </div>
            </div>
        </div>
    </div>
    <ConfirmBox v-if="removeConfirm && invoicePaymentRecords.length === 0" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
    <ConfirmBox :warning="true" v-if="removeConfirm && invoicePaymentRecords.length > 0" :message="'One or more payment is linked, please remove/delete payments associated with this invoice.'" :title="'Cant delete - payment exists'" />
    <ItemDetail v-if="itemdetailShow" :itemDetail="selectedItemDetails" />
    <TaxBreakDown v-if="itemBreakDonwShow" :vissibleTaxId="vissibleTaxId" :itemList="invoiceDetail.selectedItemList" />
    <SendBokkingCon :entitySlug="'invoice'" :detailId="parseInt(this.invoiceDetail.invoiceId)" :customerId="invoiceDetail.customerId" v-if="isSendInvoiceEmail"  />
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import SendBokkingCon from '@/View/components/SendBokkingCon.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import ItemDetail from './components/ItemDetail.vue'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import TaxBreakDown from './components/TaxBreakDown.vue'
export default {
  name: "customer",
  components: {
    Button,
    ConfirmBox,
    ItemDetail,
    TaxBreakDown,
    SendBokkingCon,
  },
  data() {
    return {
      showHotNOtes: false,
      displayTagSelects: false,
      isCustomerPermission: FilterPermissions('customer'),
      canPaymentRecordPermission: FilterPermissions('record_payment'),
      canEmailSendPermission: FilterPermissions('invoice_email'),
        invoiceDetail: null,
        titleObj: [
          {title: 'Compnay Name', icon: '', sortName: ''},
          {title: 'Transaction Id', icon: '', sortName: ''},
          {title: 'Payment Method', icon: '', sortName: ''},
          {title: 'Amount', icon: '', sortName: ''},
          {title: 'Recorded By', icon: '', sortName: ''},
          {title: 'Date', icon: '', sortName: ''},
        ],
        finalTaxList: [],
        invoicePaymentRecords: [],
        taxesAmmount: 0,
        vissibleTaxId: 0,
        selectedItemDetails: null,
        itemdetailShow: false,
            removeConfirm: false,
        itemBreakDonwShow: false,
        showMenuList: false,
        isSendInvoiceEmail: false,
    };
  },
  created() {},
  mounted() {
    this.getInvoiceDetails()
    this.$root.$on('closeItemDetailHandler', (data) => {
        console.log(data);
        this.itemdetailShow = false
        this.selectedItemDetails = null
    })
    this.$root.$on('closeBreackDownHandler', (data) => {
        console.log(data);
        this.itemBreakDonwShow = false
        this.vissibleTaxId = 0
    })
    this.$root.$on('confirmBoxHandler', (response) => {
      document.body.style = 'overflow: visible;'
        if (response) {
          this.deletesInvoice()
        }
        this.removeConfirm = false
    })
    this.$root.$on('bookingConfirmationHandler', () => {
      document.body.style = 'overflow: visible;'
        this.isSendInvoiceEmail = false
        this.showMenuList = false
    })
  },
  methods: {
    isSendEmail () {
      if (this.canEmailSendPermission) {
        document.body.style = 'overflow: hidden;'
        this.isSendInvoiceEmail = true
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Send Email`})
      }
    },
    closeList () {
      this.showMenuList = false
    },
     updateInvoice () {
      this.$router.push({name: 'createInvoice', query: {invoiceId: this.invoiceDetail.invoiceId}})
    },
     paymentDetail (id) {
      this.$router.push({name: 'paymentDetails', params: {paymentId: id}})
    },
    showBreakDownDetail (taxId) {
        this.itemBreakDonwShow = true
        this.vissibleTaxId = taxId
    },
    showItemDetail (detail) {
        this.itemdetailShow = true
        this.selectedItemDetails = detail
    },
    showPayDetail (id) {
        this.invoicePaymentRecords[id].showInfo = !this.invoicePaymentRecords[id].showInfo
    },
    deletesInvoice () {
      this.$store.dispatch("SetLoader", {  status: true,  message: "Loading...",});
      MyJobApp.deleteInvoice(
        parseInt(this.$route.params.invoiceId),
        (response) => {
          if (this.$route.query.fromCall === 'customer') {
                this.$router.push({name: 'customerDetails', params: {customerId: this.invoiceDetail.customerId}, query: {activeTab: 1}})
          } else {
            this.$router.go(-1)
          } 
         this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    recordPayment () {
      if (this.canPaymentRecordPermission) {
        this.$router.push({name: 'recordPayment', query:{customerId: this.invoiceDetail.customerId, invoiceId: this.invoiceDetail.invoiceId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Record Payment`})
      }
    },
    getInvoiceDetails() {
      console.log("call", this.$route);
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.invoiceDetail(
        parseInt(this.$route.params.invoiceId),
        (response) => {
          
          this.invoiceDetail = response.Data;
          if (response.Data.selectedItemList.length > 0) {
           this.calculateTaxes(response.Data.selectedItemList) 
          }
          this.getPaymentlist()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getPaymentlist() {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.getPaymentList(
          0,
          1000,
          '',
          0,
          this.invoiceDetail.invoiceId,
          (response) => {
            let tempList = []
            // this.invoicePaymentRecords = response.Data.tableRow !== null ? response.Data.tableRow  : []
            if (response.Data.tableRow !== null) {
               response.Data.tableRow.map((data)=> {
                    tempList.push({...data, showInfo: false})
                }) 
            }
            if (tempList.length === 1) {
              this.displayTagSelects = true
            }
            this.invoicePaymentRecords = tempList
            console.log("response getInvoicesList", response);
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
    },
    calculateTaxes (itemList) {
        let list = itemList
        list.map((data) => {
            let itemAmount = data.itemAmount
            data.taxRateList.map((rate) => {
                let itemTaxamount = itemAmount * rate.taxValue / 100
                let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
                if (fIndex >= 0) {
                    this.finalTaxList[fIndex].ammount += itemTaxamount
                } else {
                    let obj = {
                        taxName: rate.taxName,
                        taxValue: rate.taxValue,
                        ammount: itemTaxamount,
                        taxId: rate.taxId
                    }
                    this.finalTaxList.push(obj)
                }
            })
            
        })
        this.finalTaxList.map(data=> {
            this.taxesAmmount += data.ammount
        })
    }
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
  },
};
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.name_col {
    min-width: 200px;
    max-block-size: 200px;
}
.des_col {
    min-width: 250px;
    max-block-size: 250px;
}
.pric_col {
    min-width: 100px;
    max-block-size: 100px;
}
.qnty_col {
    min-width: 100px;
    max-block-size: 100px;
}
.amt_col {
    min-width: 100px;
    max-block-size: 100px;
}
</style>